/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap');


@font-face {
  font-family: 'Articulat CF';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/articulat-cf-thin.otf'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/articulat-cf-thin.otf') format("opentype");
}

@font-face {
  font-family: 'Articulat CF';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/articulat-cf-light.otf'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/articulat-cf-light.otf') format("opentype");
}

@font-face {
  font-family: 'Articulat CF';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/articulat-cf-regular.otf'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/articulat-cf-regular.otf') format("opentype");
}

@font-face {
  font-family: 'Articulat CF';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/articulat-cf-medium.otf'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/articulat-cf-medium.otf') format("opentype");
}

@font-face {
  font-family: 'Articulat CF';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/articulat-cf-bold.otf'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/articulat-cf-bold.otf') format("opentype");
}

.marked {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22252%22%20height%3D%22252%22%20viewBox%3D%220%200%20252%20252%22%20fill%3D%22none%22%3E%0A%3Ccircle%20cx%3D%22126%22%20cy%3D%22126%22%20r%3D%22126%22%20fill%3D%22%23575757%22%2F%3E%0A%3C%2Fsvg%3E");
    background-size: 10%;
    background-repeat: no-repeat;
    background-position: bottom;
}

.ddh-member-open {
    background-image: url('/assets/ddh-map/marker-icon-2x.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
}

.ddh-member-closed {
    background-image: url('/assets/ddh-map/marker-offline-icon-2x.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
}

.ddh-member-active {
    background-image: url('/assets/ddh-map/marker-user-icon-2x.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
}

.ddh-member-appointment-only {
  background-image: url('/assets/ddh-map/marker-appointment-only-icon-2x.png');
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

:root {
    --ion-font-family: 'Articulat CF', 'Arial', 'sans-serif';
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 1px;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  background-color: var(--ion-color-primary);
  padding: 9px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}

ion-button {
  text-transform: none;
 }

.flex {
  display: flex;
}

$grid-breakpoints: (
 xs: 0, // Phone (0px - 575px)
 sm: 576px, // Phablet (576px - 767px)
 md: 768px, // Tablet vertical (768px - 991px)
 lg: 992px // Tablet horizontal, Desktop (992px and above)
);

$grid-max-widths: (
 lg: 992px
);

.sentry-error-embed-wrapper {
  z-index: 20003 !important;
}

.ddh-card-description {
  font-size: 12px !important;
  color: #646464;
  line-height: 1.2;
}


.bold {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.small {
  font-size: 80% !important;
}

.light {
  font-weight: lighter;
}

.loose {
  line-height: 1rem
}


.section-title {
  font-weight: 600;
  font-size: x-large;
}

.hidden {
  display: none;
}


@media only screen and (min-width: 768px) {
  ion-modal {
    --height: 90% !important;
  }
}
